<template>
    <main class="main mt-8">
        <nav aria-label="breadcrumb" class="">
            <div class="container">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <a href="/orders">
                            <button type="button" class="btn btn-danger">
                                <i class="fa fa-backward"></i>
                                 الرجوع 
                            </button>
                        </a>
                    </li>
                </ol>
            </div>
        </nav>
        <div class="container">
            <div class="row" dir="rtl">
            <aside class="sidebar col-lg-2">
                <div class="widget widget-dashboard">
                    <h3 class="widget-title text-right">الحساب</h3>
                    <ul class="list">
                        <li><a href="/account">معلومات الحساب</a></li>
                        <li class="active"><a href="/orders">الطلبيات</a></li>
                        <li><a href="/wallet">المحفظة</a></li>
                        <li><a href="/addresses">العناوين</a></li>
                    </ul>
                </div>
            </aside> 
                <div class="col-lg-8 order-lg-last dashboard-content">
                    <div class="col-6 col-sm-12 product-default" v-for="item in orderProducts" :key="item.id">
                        <b-card-group deck>
                          <b-card header="Card with list group">
                            <b-list-group>
                              <b-list-group-item href="#">Cras justo odio</b-list-group-item>
                              <b-list-group-item href="#">Dapibus ac facilisis in</b-list-group-item>
                              <b-list-group-item href="#">Vestibulum at eros</b-list-group-item>
                            </b-list-group>
    
                            <p class="card-text mt-2">
                              Quis magna Lorem anim amet ipsum do mollit sit cillum voluptate ex nulla tempor. Laborum
                              consequat non elit enim exercitation cillum aliqua consequat id aliqua. Esse ex consectetur
                              mollit voluptate est in duis laboris ad sit ipsum anim Lorem.
                            </p>
                          </b-card>
    
                          <b-card no-body header="Card with flush list group">
                            <b-list-group flush>
                              <b-list-group-item href="#">Cras justo odio</b-list-group-item>
                              <b-list-group-item href="#">Dapibus ac facilisis in</b-list-group-item>
                              <b-list-group-item href="#">Vestibulum at eros</b-list-group-item>
                            </b-list-group>
    
                            <b-card-body>
                              Quis magna Lorem anim amet ipsum do mollit sit cillum voluptate ex nulla tempor. Laborum
                              consequat non elit enim exercitation cillum aliqua consequat id aliqua. Esse ex consectetur
                              mollit voluptate est in duis laboris ad sit ipsum anim Lorem.
                            </b-card-body>
                          </b-card>
                        </b-card-group>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
export default {
    data() {
        return {
            orderProducts: [],
            order: [],
        }
    },
    mounted() {
        this.axios({
            url: 
process.env.VUE_APP_URL+'/api/order-products?orderId.equals='+this.$route.params.id,
            method: 'GET',
            headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
        })
        .then(response => {
            this.orderProducts = response.data
            console.log(this.orderProducts)
        })
        this.axios({
            url: 
process.env.VUE_APP_URL+'/api/orders/'+this.$route.params.id,
            method: 'GET',
            headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
        })
        .then(response => {
            this.order = response.data
            console.log(this.order)
        })
    }
}
</script>

<style scoped>
    .order-box {
        background: white;
        border: 1px solid #ebebeb;
        border-radius: 0.5rem;
        padding: 5px 0px;
        height: 100%;
    }
    .list li a:before {
        content: none;
    }
    .list {
        text-align: right;
    }
    button {
        color: white;
        border-radius: 6px;
    }
    button a {
        color: white;
        font-size: 17px;
        font-weight: bold;
    }
</style>